.footer{
	padding: 3rem;
	background-color:black;
	
	text-align: center;
	left: 0;
	bottom:0;
	margin-top:30%;
	width: 100%;
	display: flex;
	overflow-x: hidden;
  }
  .footer-text{
	  color:white;
	  margin:auto;}
	  a{
		  text-decoration: underline;
		  color:white;
		  
	  }
  
