

	body{
		z-index:10000
	}
.navbar{
	background-color: white;
	height: 150px;
display: flex;
align-items: center;
overflow-x: hidden;
overflow-y: hidden;
z-index: 10000;
}

.logo{
	width:280px;
	z-index: 1000000;
	margin-left:1em;
	margin-top: 1.5em;
}