html, body {
	overflow-x: hidden;
}
body{
	position: relative;
	background-color: var(--background);
}
.title{
	font-size: 70px;
	font-weight:bold;
	max-width: 650px;
	margin-left:1em;
	margin-top: 1em;
	color: var(--secondary);
}
.topImage{
	height:450px;
	float:right;
	margin-top: -26em;;
}
.secondary-title{

	font-size: var(--general-text-size);
	max-width: 250px;
	text-align:right;
	line-height: 1.5;
	color:white;
	margin-left: 60%;
	min-width:250px;
}
.first-container-box{
	padding-top:2px;
	z-index:-10 !important;
	/* background-color: var(--section); */
	background-color: var(--purple);
	margin-top:50px;
}
.type-container{

	height: 20em;
}
.secondary-title-container{
	background-color: var(--purple);
	margin-right: 35%;
	padding:15px;
	border-radius: .2em;
	/* padding-right:10px; */
}
.top-texts{
	max-width: 350px;
	margin:auto;
	text-align: left;
	color:white;
	font-size:var(--general-text-size);
	line-height: 1.5;
	z-index: 3 !important;
	font-weight:bold;
}
.box{
	padding: 30px;
	border-radius: 2em;
	/* background-color:var(--purple); */
	width:400px;
	z-index: 1000 !important;
}
.box2{
	float:left;
}
.box-container{
	margin-top: 5em;
	display:flex;
	margin-left: 2em;
	margin-right:2em;
	justify-content: space-between;
	
}

.firstpointpic{
	height:600px;
	margin:auto;
	float:right;
}
.top-third-text{
	max-width: 500px;
	margin:auto;
	text-align: center;
	color:var(--purple);
	font-size:var(--general-text-size);
	line-height: 1.5;
}
.box3{
	width:700px;
	margin: auto;
}
.third-box-container{
	margin-top:5em;
	display:flex;
	align-items: center;
	
}
.container-containers{
	/* background-color: rgb(84,26,152,0.3); */
	background-color: var(--purple);
	padding: 10px;
	margin-top: 3em;
}
.advantage{
	margin:auto;
	font-size: var(--title-text-size);
	max-width:550px;
	text-align: center;
	font-weight: bold;
	color: white;
	/* background-color: white; */
}
.advantage-container{
	margin-top:6em;
	/* background-color: white; */
}
.advantage-points-container{
	padding:100px;
	align-items: center;
	margin-top:-18em;
	display:flex;
	justify-content:space-between;
	flex-wrap: wrap;
	
}

.advantage-points{
	color: var(--secondary);
	text-align:center;
	margin:auto;
	margin-top:2em;
	max-width:400px;
	padding-bottom: 2em;
	font-size: var(--general-text-size);
}
.advantage-box{
	flex-flow: 2 2 auto;
	flex-wrap: wrap;
	margin-left:2em;
	flex: 1 1  500px;
	padding:20px;
	width: 300px;
	margin:auto;
	border-radius: 4em;
	margin-top: 3em;;
	background-color: white;
	max-width: 400px;
	min-width:400px;
}
.advantage-tites{
	font-size:35px;
	margin-top:0em;
	margin-left:3em;
	margin:auto;
	text-align:center;
	padding-top:1em;
	font-weight: bold;
	color: rgb(20,178,205);	
	z-index:1000 !important;
}
.digital-title{
	color:white;
}
.digital-container-full{
	background-color: var(--purple);
}
.trythis{
	background-color: var(--purple);
	padding-bottom:900px;
	padding-top:50px;
	margin-top: 4em;
}

.wallet-actual-container{
	margin-top: -3em;
	font-size: var(--general-text-size);
	flex-direction: column;
	color: white;
	line-height: 1.5;
	float:left;
	background-color: none;
}
.wallet{
	flex: 1 1  100px;
	padding:40px;
	border-radius: 95em;
	margin-top: 1em;
}
.wallet-image{
	height:400px;
	margin-top: 10em;
	margin-right:5em;
	float:right;
	
}
.new-blockchain-container{
	margin-top: -600px;;
}
.blockchain-container{
	
	margin-top: 1em;
	font-size: var(--general-text-size);
	flex-direction: column;
	color: white;
	line-height: 1.5;
	float:right;
	margin-right: 3em;

}
.blockchain-title{
	margin-top: 20em;
	color: var(--secondary);

}
.blockchain{
	padding:60px;
	border-radius: 1em;
	font-weight:bold;
	flex-direction: column;
	float:left;
	margin-top: 1.5em;
	background-color: rgb(20,178,205);
}
.blockchain-image{
	height: 500px;
	float:left;
	margin-top: 5em;
}
.casestudy{
	font-size: 50px;
	max-width:600px;
	font-weight: bold;
	color: var(--secondary);
	background-color: white;
	margin-left:2em;
	margin-top: 3em;
}
.flash{
	max-width:900px;
	margin-top:18em;
	padding-top:50px;
	color:black;
}
.flashimage{
	height:100px;
	width:auto;
	visibility: visible;
}
.flash-full-image{
	float:right;
	margin-right: 15em;
	height:300px;
	
}
.flash-box{
	background-color: black;
	color: var(--flash-color);
	font-size: var(--general-text-size);
	line-height: 1.5;
	padding:50px;
}
.flash-contains{
	background-color: rgb(68,64,71,0.6);
	height: 1000px !important;
	width:100%;
}
.coinApp-contain{
	margin-top: -500px;
	background-color: var(--purple);
	height:700px;
}
.coinapptech{
	margin-top:15em;
	padding-top:50px;
	color:white;
}
.coinpara{
	font-size: var(--general-text-size);
	text-align: center;
	line-height: 1.5;
	margin-right:1em;
	padding: 40px;
	color: white;
	background-color: var(--purple);
	border-radius: 4em;
}
.para-image{
	height: 470px;
	float:left;
	margin-top:3em;
}
.coin{
	margin-left:2em;
	/* flex: 1 1  500px; */
	padding:20px;
	/* width: 300px; */
	/* margin:auto; */
	margin-top: 4em;;
	max-width: 500px;
	min-width:200px;
	float:right;
	
}
.flashmobileimage{
	visibility: hidden;
}
.flashcontain{
	margin-top: -10em;
	float:left;
	margin-left:2em;
}
.wallet{
	background-color: var(--purple);
}

@media (min-width:1370px) and (max-width:1824px){
 .secondary-title{
	margin-left: 70%;
 }
 .topImage{
	margin-top: -29em;;
 }
}
@media (max-width:1175px) and (min-width: 500px){

	.coin{
		float:none;
		margin-right:unset;
		display: unset;
	
	justify-content:unset;
	flex-wrap: unset
	}
	.para-image{
		float:none;
		position: absolute;
		margin-top: -42em;
		margin-left: 24%;;
	}
	.coinpara{
		float:none;
		margin-top:15em;
		margin-left:20%;
		max-width:700px;
		margin-right:unset;
		
	}



}
@media (max-width:1042px) and (min-width: 500px){
	.firstpointpic{
		margin-top:-14em;
		margin-left: 27em;
	}
	.box2{
		margin-top: -8em;;
	}

	.box-container{
		flex-wrap: wrap;
		flex-direction: column;
		align-items: stretch;
		margin-left:.5em;
		/* margin:auto; */
	}
}

@media (max-width:863px) and (min-width: 500px){
	.firstpointpic{
		margin-top:0em;
		margin-left: -2em;
	}
	.box2{
		margin-top: 0em;
	}
	.wallet-image{
		height: 300px;
		position: absolute;
		/* margin-right:2em; */
		margin-left:10em;
		width:auto;
		margin-top: -70em;
		float:none;
	}
	.trythis{
		padding-bottom:0px;
	}
	.wallet-actual-container{
		margin-top:12em;
		float:none;
	}
	.para-image{
		margin-top: -46em !important;
		margin-left: 2.5em !important;;
		margin-right:2em;
	}
	.coinpara{
		margin-left: 2.5em !important;;
		margin-right:2em;
	}

	

}
@media (max-width:1276px) and (min-width: 863px){
	.wallet-image{
		height: 25em;
		position: absolute;
		margin-right:2em;
		margin-left:-6em;
		width:auto;
		margin-top: 10em;
	}
	.wallet-actual-container{
		margin-left: -3em;
	}

}

@media (max-width:1032px) and (min-width: 500px){

	.blockchain-image{
		height:350px;
		margin-left: 30%;
		margin-top:-69em;
	}
	.blockchain-container{
		margin-top: 22em;
		float:none;
		/* flex-direction: column; */
	
	}
	.blockchain{
		float:none;
	}
	.mobile-title-flash{
		margin-top:0em;
		font-size: 30px;;
	}
	.flashcontain{
		float:none;
	}
	.flash-full-image{
		margin-top:-89em;
		float:none;
		position: absolute;
		margin-left:30%;
	}
	.coinapptech{
		margin-top:0em;
	}

}
@media (max-width:1002px){
	.secondary-title{
		margin-left: 40%;
		
	 }
	 .flash-contains{
		 margin-top: -300px;
		 /* padding-top: px; */
	 }
	 .title{
		 z-index: 1000;
	 }
	 .topImage{
		 opacity:0.7;
		 position: absolute;
		 z-index:-10 !important;
		 margin-left: 18vw;
	 }
	 .coinApp-contain{
		 margin-top:40px;
		height: 900px;;
	 }
}
@media (max-width:800px){
	
	 .secondary-title-container{
		min-width: 12em;
		width:30em;
		margin-right: -20em;
	 }
	 .topImage{
		margin-top: -29em;
	 } 
}

@media  (max-width:612px) and (min-width: 500px){
	.para-image{
		margin-left: -4em !important;
		margin-top: -52em !important;
	}
}

@media screen and (max-width:500px){
	.trythis{
		padding-bottom:0px;
	}
	.topImage{
		float:none;
		position: absolute;
		z-index:-10 !important;
		/* background: rgba(0, 151, 19, 0.6); */
		opacity: .5;
		margin-top:-20em;
		margin-left:-12em;
	}
	.flashimage{
		visibility: hidden;
	}
	.flashmobileimage{
		height:100px;
		width:auto;
		visibility: visible;
		margin-left: 45%;
		/* margin-left: 2%; */
	}
.title{
	font-size: 60px;
	max-width:90%;
	margin-top:0em;
	z-index: 100 !important;
}
.secondary-title-container{
	margin-top: 11em;;
	margin-right: 1em;
	width: 25em;
}
.secondary-title{
	margin-left: 25%;
}
.firstpointpic{
	margin-left: -4.7em;
	height:400px;
}
.box{
	width:400px;
	margin-left:.5em;
	max-height: none;
}
.box-container{
	flex-wrap: wrap;
	flex-direction: column;
	align-items: stretch;
	margin-left:1.8em;
}
.box, .box2 {
	min-width:200px;
	width:350px;
}
.box2{
	float:none;
	margin-top: 2em;
}
.box3{
	margin-left: 2.4em;
	margin-right: -2.9em;
	width:340px;
	/* width:800px; */
}
.advantage-mobile{
	margin-top: -10em;
	margin-left: -2em
}
.advantage-box{
	margin-left:-2.4em;
	width: 350px;
	min-width:350px;
}
.advantage{
	margin-left: 0em;
	margin-top: 2em;;
}
.wallet{
	margin-left: 1em;
	padding:40px;
	min-width: 350px;
}
.wallet-image{
	height:220px;
	margin-left:2em;
	float:none;
	position: absolute;
	margin-top: -70em;
}

.digital-title{
	margin-top:0em;
}
.mobile-wallet-container{
	margin-top: 14em;
	float:none;
	margin-left:-1em;
	padding:30px;
}
.new-blockchain-container{
	margin-top: 10px;;
}
.blockchain{
	margin-top: -1.5em;
}
.blockchain-image{
	height:270px;
	margin-left:2.8em;
	/* position:absolute; */
	margin-top:-60em;
}
.blockchain-container{
	margin-top:17em;
	float:none;
	margin-left: -1.3em;;
	padding-bottom: 4em;
}
.flash-contains{
	margin-top: 200px;
	padding:0px;
	background-color: white;
}
.flash-full-image{
	visibility: hidden;
}
.bcm{
	margin-left: 3em;
	margin-right: 1em;
	padding:50px;
	margin-top:1em;
	text-align: center;
	min-width: 350px;
}
.flash-box{
	margin-left: 2em;
	text-align: center;
}
.mobile-title-flash{
	margin-top: 13.5em;
	width:95%;
}
.firstflash{
	margin-top: 3em;
	margin-left:9.5em;
}
.flashcontain{
	margin-top: 4em;;
	min-width: 300px;
	width:300px;
	margin-left:-.3em;
	float:none;
}
.flashlast{
	margin-top:1.5em;
	margin-left:10em;
}
.coinApp-contain{
	margin-top: 1000px;
	height:1150px;
}
.coinpara{
	margin-left: -10em;
	margin-right: -3.4em;
	width:350px;
	margin-top:7em;
	
}
.coinapptech{
	margin:unset;
	margin-top:2em;
	position: absolute;
}
.para-image{
	float:none;
	margin-left: -2em;
	width: 450px;
	height:auto;
	position: absolute;
	margin-top:12em;

}




}
