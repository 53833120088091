@import "~@fontsource/alegreya-sans";

:root{
	--purple: #541A98;
	--secondary: #444047;
	--general-text-size: 20px;
	--title-text-size: 44px;
	--flash-color: rgb(242,214,117);
	--background: #ffffff;
	--section: rgb(20,178,205, 0.3);

}
*{
	box-sizing: border-box;
    margin: 0;
    padding: 0;
	font-family: "Alegreya Sans";
}
/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
